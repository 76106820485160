<template>
  <a-drawer
    width="1200"
    placement="left"
    title="公司信息"
    :visible="visible"
    @close="onClose">
    <div class="basic-box">
      <div class="basic-box-title">基本信息</div>
      <div class="basic-box-content">
        <div class="content-item flex-25">
          <div class="content-item-label">企业名称：</div>
          <div class="content-item-value">{{ enterpriseInfo.enterpriseName }}</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">社会信用代码：</div>
          <div class="content-item-value">{{ enterpriseInfo.creditCode }}</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">企业类型：</div>
          <div class="content-item-value">{{ enterpriseInfo.enterpriseType }}</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">企业规模：</div>
          <div class="content-item-value">{{ enterpriseInfo.enterpriseScale }}</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">成立日期：</div>
          <div class="content-item-value">{{ enterpriseInfo.establishmentTime }}</div>
        </div>
        <div class="content-item flex-75">
          <div class="content-item-label">法人姓名：</div>
          <div class="content-item-value">{{ enterpriseInfo.legalName }}</div>
        </div>
        <div style="display: flex; width: 100%;">
          <div class="content-item flex-50">
            <div class="content-item-label">营业执照：</div>
            <div class="content-item-value">
              <img class="content-item-value-image" :src="enterpriseInfo.businessLicense" @click="$hevueImgPreview(enterpriseInfo.businessLicense)" ></img>
            </div>
          </div>
          <div class="content-item flex-50">
            <div class="content-item-label">企业介绍：</div>
            <div class="content-item-value">{{ enterpriseInfo.description }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="basic-box">
      <div class="basic-box-title">联系人信息</div>
      <div class="basic-box-content">
        <div class="content-item flex-25">
          <div class="content-item-label">联系人：</div>
          <div class="content-item-value">{{ rowItem.name }}</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">性别：</div>
          <div class="content-item-value">{{ rowItem.gender }}</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">联系方式：</div>
          <div class="content-item-value">{{ rowItem.phone }}</div>
        </div>
      </div>
    </div>
    <div class="basic-box">
      <div class="basic-box-title">资质附件</div>
      <div class="basic-box-content">
        <div class="content-item flex-100" v-for="(item,index) in fileList" :key="index">
          <div class="content-item-label min-150">{{ item.title }}：</div>
          <div class="content-item-value">
            <img class="content-item-value-image" @click="$hevueImgPreview(file.filePath)" :src="file.filePath" v-for="(file, idx) in item.url" :key="idx" ></img>
          </div>
        </div>
        <div class="content-item flex-100">
          <div class="content-item-label  min-150">备注：</div>
          <div class="content-item-value">{{ rowItem.remark || '无' }}</div>
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
export default {
  name: 'LookDetails',
  data () {
    return {
      visible: false,
      rowItem: {}
    }
  },
  computed: {
    enterpriseInfo () {
      return this.rowItem.enterpriseInfo || {}
    },
    fileList () {
      return JSON.parse(this.rowItem.fileList || '[]')
    }
  },
  methods: {
    show (rowItem) {
      this.rowItem = { ...rowItem }
      this.visible = true
    },
    onClose () {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.basic-box{
    border: 1px solid #D9D9D9;
    color: #666666;
    margin-bottom: 24px;
    &-title{
        height: 60px;
        border-bottom: 1px solid #D9D9D9;
        line-height: 60px;
        font-size: 16px;
        color: #3D3D3D;
        padding: 0 20px;
    }
    &-content{
        padding: 30px 40px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .content-item{
        display: flex;
        margin-bottom: 14px;
        &-value{
            flex: 1;
            &-image{
              width: 120px;
              height: 150px;
            }
        }
    }
    .flex-100{
        width: 100%;
    }
    .flex-75{
        width: 75%;
    }
    .flex-50{
        width: 50%;
    }
    .flex-25{
        width: 25%;
    }
    .min-150{
        min-width: 150px;
        white-space: pre-wrap;
        text-align: right;
    }
}

</style>
